import S from "../../../components/forms/Switch.module.css";
import C from "./ChooseMethod.module.css";

import { useEffect, useRef, useState } from "react";
import { useFocus } from "react-aria";
import { Switch } from "react-aria-components";
import { Separator } from "../../../components/Separator.tsx";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import type { BaseProps } from "../../../utils/baseProps.ts";
import { cn } from "../../../utils/classNames.ts";
import { useControlledState } from "../../../utils/react/useControlledState.tsx";
import type { PaymentMethod, PaymentMethodId } from "../PaymentMethod.tsx";
import type { AddMethodProps } from "./AddMethodParams.ts";

export interface ChooseMethodProps extends ChooseMethodContentProps {
    hasAutomatedSwitch?: boolean;
    defaultOnlyAutomated?: boolean;
}

export function ChooseMethod({
    hasAutomatedSwitch,
    defaultOnlyAutomated = false,
    methods,
    ...props
}: ChooseMethodProps) {
    //#region Hooks
    const [onlyAutomated, setAutomated] = useState<boolean>(defaultOnlyAutomated);
    //#endregion

    methods = methods.filter((m) => !onlyAutomated || m.isAutomated);

    return (
        <>
            <div className={cn("flex items-center justify-between", !hasAutomatedSwitch && "pt-2")}>
                <p>Choose method</p>

                {hasAutomatedSwitch && (
                    <Switch className={S.Switch} isSelected={onlyAutomated} onChange={setAutomated}>
                        <span className="color-muted">Only automated methods</span>
                        <div className={S.indicator} />
                    </Switch>
                )}
            </div>

            <div className="py-3">
                <Separator />
            </div>

            <ChooseMethodContent className={C.modalList} methods={methods} {...props} />
        </>
    );
}

export interface ChooseMethodContentProps extends BaseProps {
    methods: PaymentMethod[];
    isDisabled?: boolean;
    scrollToSelected?: boolean;

    formProps: AddMethodProps;

    /** `null` means nothing selected */
    defaultValue?: PaymentMethodId | null;
    value?: PaymentMethodId | null;
    onChange?: (id: PaymentMethodId) => void;
}

export interface MethodIconProps {
    isSelected: boolean;
}

export function ChooseMethodContent({
    methods,
    formProps,
    value: controlledValue,
    defaultValue = null,
    onChange,
    isDisabled = false,
    scrollToSelected = false,
    ...baseProps
}: ChooseMethodContentProps) {
    //#region Hooks
    const [value, setValue] = useControlledState(controlledValue, defaultValue, onChange);
    const selectedRef = useRef<HTMLDivElement>(null);
    const [isFocus, setFocus] = useState(false);
    //#endregion

    const { focusProps } = useFocus({
        onFocus: (event) => {
            setValue(event.target.id as PaymentMethodId);
            setFocus(true);
        },
        onBlur: () => setFocus(false),
    });

    function onClick(value: PaymentMethodId) {
        setValue(value);
    }

    // Scroll the first selected item into view on mount
    useEffect(() => {
        if (scrollToSelected)
            selectedRef.current?.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div {...baseProps}>
            {methods.map(({ id, icon, AddForm, name }) => {
                const isSelected = value === id;
                const formContent = isSelected ? (
                    <ContentLoadingSuspense>
                        <AddForm {...formProps} />
                    </ContentLoadingSuspense>
                ) : undefined;
                return (
                    <div
                        key={id}
                        id={id}
                        {...focusProps}
                        // biome-ignore lint/a11y/noNoninteractiveTabindex: Work in progress keyboard navigation
                        tabIndex={0}
                        ref={isSelected ? selectedRef : undefined}
                        data-selected={isSelected ? true : undefined}
                        data-disabled={isDisabled ? true : undefined}
                        data-focused={isFocus ? true : undefined}
                        className={C.ListItem}
                        onClick={() => onClick(id)}
                    >
                        <div className={C.item}>
                            <div className="flex items-center justify-between">
                                <div>{name}</div>
                                {icon({ isSelected })}
                            </div>
                        </div>

                        {formContent}
                    </div>
                );
            })}
        </div>
    );
}
