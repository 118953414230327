import C from "./forms/BillingSelect.module.css";

import type { BillingAccountCard } from "@warrenio/api-spec/spec.oats.gen";
import { mcn } from "../../utils/baseProps.ts";
import { parseCardAdditionalData } from "./billingCardsQuery.ts";
import { getCardIconClass, getMethodLinkIconClass, getPaymentMethodBrandText } from "./billingLogic.tsx";
import type { PaymentMethodId } from "./PaymentMethod.tsx";

//#region Icons

function getPaymentMethodIconClass(card: BillingAccountCard) {
    return getCardIconClass(parseCardAdditionalData(card));
}

export function PaymentMethodIcon({ card, ...props }: { card: BillingAccountCard }) {
    return <span {...mcn(`${C.MethodIcon} inline-block ${getPaymentMethodIconClass(card)}`, props)} />;
}

export interface PaymentLinkIconProps {
    icon: PaymentMethodId;
    isSelected?: boolean;
}

export function PaymentLinkIcon({ icon, isSelected = false, ...props }: PaymentLinkIconProps) {
    return <span {...mcn(`${C.MethodIcon} inline-block ${getMethodLinkIconClass(icon, isSelected)}`, props)} />;
}

//#endregion

export function CardShortInfo({ card }: { card: BillingAccountCard }) {
    const primary_card = parseCardAdditionalData(card);
    if (!primary_card) {
        return null;
    }

    const { type, last4, expire_month, expire_year } = primary_card;
    if (type === "paying_by_invoice") {
        return <span>Paying By Invoice</span>;
    } else {
        return (
            <span>
                <span className="text-capitalize">
                    {last4
                        ? `${getPaymentMethodBrandText(primary_card)}*${last4}`
                        : getPaymentMethodBrandText(primary_card)}
                </span>
                <span className="color-muted">
                    {" "}
                    ({expire_month}/{expire_year})
                </span>
            </span>
        );
    }
}
