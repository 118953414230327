import type { QueryClient } from "@tanstack/query-core";
import type { QueryKey } from "@tanstack/react-query";
import { parallelMap } from "@warrenio/utils/collections/parallelMap";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { logPromiseErrors } from "../promise/logPromiseErrors.ts";

async function invalidateQueryKeys(queryClient: QueryClient, keys: QueryKey[]) {
    return await parallelMap(keys, async (queryKey) => await queryClient.invalidateQueries({ queryKey }));
}

export async function invalidateQueries({
    queryClient,
    immediate,
    background,
}: {
    queryClient: QueryClient;
    immediate: QueryKey[];
    background?: QueryKey[];
}) {
    logPromiseErrors("Query invalidation error", await invalidateQueryKeys(queryClient, immediate));

    if (background) {
        discardPromise(
            invalidateQueryKeys(queryClient, background).then((results) =>
                logPromiseErrors("Background invalidation error", results),
            ),
        );
    }
}
