import type { Stripe } from "@stripe/stripe-js";
import type { StripeCardConfig } from "@warrenio/api-spec/spec.oats.gen";
import React, { useImperativeHandle } from "react";
import invariant from "tiny-invariant";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { useApiClient } from "../../api/apiClient.store.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import type { CardMethod } from "../PaymentMethod.tsx";
import { defaults } from "../paymentMethodDefaults.tsx";
import { TopUpResult, type TopUpParams } from "../topup/TopUpParams.ts";
import { creditBuyRequest } from "../topup/topUpUtils.ts";
import { throwStripeError } from "./throwStripeError.ts";
import { getStripe } from "./utils.ts";

export function makeStripe(config: StripeCardConfig): CardMethod {
    const AddForm = React.lazy(() => import("./StripeAddForm.tsx"));
    return {
        ...defaults(config),
        type: "creditcard",
        AddForm(props) {
            return <AddForm {...props} stripe={getStripe(config)} config={config} />;
        },
        TopUpForm({ actionsRef }) {
            const apiClient = useApiClient();

            useImperativeHandle(actionsRef, () => ({
                async topUp(params) {
                    return await cardTopUp(params, apiClient, getStripe(config));
                },
            }));

            return null;
        },
    };
}

async function cardTopUp(
    { account, getCard, amount, progress }: TopUpParams,
    apiClient: ApiClient,
    stripePromise: Promise<Stripe>,
) {
    const payment_object_id = getCard().id;
    const billing_account_id = account.id;

    progress("Creating payment intent");
    const buyResult = await creditBuyRequest(apiClient, {
        billing_account_id,
        payment_object_id,
        amount,
        on_session: true,
    });
    invariant("client_secret" in buyResult.data, "Must be Stripe type buy response");
    // TODO: Do we need to check `success` and `message` here?

    progress("Loading Stripe");
    const stripe = await stripePromise;

    progress("Confirming payment");
    const confirmResult = await stripe.confirmCardPayment(buyResult.data.client_secret, {
        payment_method: buyResult.data.payment_method,
    });
    throwStripeError(confirmResult.error);

    progress("Saving payment result");
    const _posthookResult = getResponseData(
        await apiClient.POST("/payment/credit/buy/posthook", {
            ...jsonEncodedBody,
            body: {
                billing_account_id,
                payment_object_id,
                charge_id: confirmResult.paymentIntent.id,
            },
        }),
    );

    return TopUpResult.SUCCESS;
}
