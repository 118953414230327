import type { Ref } from "react";
import type { BillingAccountWithType } from "../billingAccountQuery.ts";

export const enum AddMethodResult {
    SUCCESS = "SUCCESS",
    VALIDATION_FAILED = "VALIDATION_FAILED",
}

export interface AddMethodParams {
    account: BillingAccountWithType;
    returnUrl: string;
}

/** Actions exposed by an "Add method" form */
export interface AddMethodActions {
    validate(): Promise<boolean>;
    addPaymentMethod(params: AddMethodParams): Promise<void>;
}

/** Properties for an "Add method" form */
export interface AddMethodProps {
    actionsRef: Ref<AddMethodActions>;
}
