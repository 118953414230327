import type { PaymentResultResponse } from "@warrenio/api-spec/spec.oats.gen";

export class ClicktopayError extends Error {
    name = "ClicktopayError";

    constructor(
        public readonly code: "failed" | "other",
        message: string,
    ) {
        super(message);
    }
}

export function parseClicktopayPaymentResult(response: PaymentResultResponse) {
    switch (response.status) {
        case "success":
            return response;
        case "failed":
            throw new ClicktopayError("failed", response.message ?? "Payment failed");
        default:
            return null; // status still pending
    }
}
