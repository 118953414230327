import type { StripeWalletConfig } from "@warrenio/api-spec/spec.oats.gen";
import type { LinkMethod } from "../PaymentMethod.tsx";
import { linkDefaults } from "../paymentMethodDefaults.tsx";
import StripeLinkTopUpForm from "./StripeLinkTopUpForm.tsx";
import { getStripe } from "./utils.ts";

export function makeStripeLink(config: StripeWalletConfig) {
    return config.methods.map((method): LinkMethod => {
        const base = linkDefaults(config, method);
        return {
            ...base,
            TopUpForm: (props) => (
                <StripeLinkTopUpForm {...props} stripe={getStripe(config)} method={method} config={config} />
            ),
        };
    });
}
