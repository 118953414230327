import type { StripeCardConfig, StripeWalletConfig } from "@warrenio/api-spec/spec.oats.gen";
import { makeOnce } from "@warrenio/utils/makeOnce";
import { notNull } from "@warrenio/utils/notNull";

// NB: Lazily load "stripe-js" since it generates extra network requests on page load
// TODO: Preload in production?
const importLoadStripe = makeOnce(async () => (await import("@stripe/stripe-js")).loadStripe);

export const getStripe = makeOnce(async (config: StripeCardConfig | StripeWalletConfig) => {
    console.debug("StripeCardProcessor: Create Stripe object");
    const loadStripe = await importLoadStripe();
    const stripe = await loadStripe(config.public_key);
    return notNull(stripe, "Stripe loading failed");
});
