import type { OmiseCreditCardConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";
import { WFakeForm } from "../../../components/forms/WHookForm.tsx";
import { NoticeBlock } from "../../../components/NoticeBlock.tsx";
import { TODO } from "../../../dev/Todo.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import type { AddMethodProps } from "../choose_method/AddMethodParams.ts";
import { addCardRequest, cardVerifyRequest } from "../choose_method/addMethodUtils.ts";
import { FormBox } from "../choose_method/FormBox.tsx";
import { CardNameField, StandardField } from "../payment_forms/components.tsx";
import { cardCvcLabel, cardExpiryLabel, cardNumberLabel } from "../payment_forms/labels.tsx";
import { omiseCreateToken, omiseLoader, usePreloadOmise, type FixedOmiseTokenParameters } from "./omiseLoader.ts";

interface OmiseCardFormInputs {
    name: string;
    number: string;
    expiration: string;
    security_code: string;
}

const _visaTest: Partial<OmiseCardFormInputs> = {
    name: "Test Success (Visa)",
    number: "4111 1111 1111 1111",
    expiration: "12/34",
    security_code: "123",
};

const _visa3dsValidationFailedTest: Partial<OmiseCardFormInputs> = {
    name: "Test 3DS Validation Failed (Visa)",
    number: "4111 1111 1114 0003",
    expiration: "01/33",
    security_code: "333",
};

const _mastercard3dsEnrollmentFailedTest: Partial<OmiseCardFormInputs> = {
    name: "Test 3DS Enrollment Failed (Mastercard)",
    number: "5555 5511 1112 0002",
    expiration: "01/32",
    security_code: "321",
};

export function OmiseCardAddForm({
    actionsRef,
    config: { public_key, use_3ds_verification },
}: { config: OmiseCreditCardConfig } & AddMethodProps) {
    //#region Hooks
    const apiClient = useApiClient();
    const form = useForm<OmiseCardFormInputs>({
        defaultValues: {},
    });
    usePreloadOmise();

    useImperativeHandle(actionsRef, () => ({
        async validate() {
            return await form.trigger(undefined, { shouldFocus: true });
        },
        async addPaymentMethod({ account, returnUrl }) {
            const billing_account_id = account.id;

            const omise = await omiseLoader();
            omise.setPublicKey(public_key);

            const { name, number, expiration, security_code } = form.getValues();
            const [expiration_month, expiration_year] = splitExpiration(expiration);
            const tokenParameters: FixedOmiseTokenParameters = {
                name,
                number,
                expiration_month,
                expiration_year,
                security_code,
            };
            const token = await omiseCreateToken(omise, "card", tokenParameters);
            console.debug("Omise token response", token);

            const addCardResult = await addCardRequest(apiClient, { billing_account_id, token: token.id });
            if (!addCardResult.is_verified) {
                const verifyResult = await cardVerifyRequest(apiClient, addCardResult.id, returnUrl);

                if (verifyResult.authorize_uri) {
                    // TODO: Handle (and remove error message below)
                    throw new Error("Omise card add verification is not implemented");
                } else if (!verifyResult.card.is_verified) {
                    throw new Error("Unknown error in card verification");
                }
            }
        },
    }));
    //#endregion

    const content = (
        <WFakeForm className="flex flex-col gap-2" form={form}>
            <CardNameField form={form} name="name" />
            <StandardField
                form={form}
                name="number"
                {...cardNumberLabel}
                options={{
                    required: requiredMessage,
                }}
            />
            <div className="flex flex-row gap-2">
                <StandardField
                    form={form}
                    name="expiration"
                    {...cardExpiryLabel}
                    options={{
                        required: requiredMessage,
                        setValueAs: (v: string) => v.trim(),
                        validate: (v) => {
                            if (!/^\d{1,2}\/\d{1,2}$/.test(v)) {
                                return "The format is MM/YY";
                            }
                            const [month, year] = splitExpiration(v);
                            if (!month || month > 12) {
                                return "Invalid month";
                            }
                            if (!year || year > 99) {
                                return "Invalid year";
                            }
                            return true;
                        },
                    }}
                />
                <StandardField
                    form={form}
                    name="security_code"
                    {...cardCvcLabel}
                    options={{
                        required: requiredMessage,
                        pattern: { value: /^\d+$/, message: "Enter digits only" },
                    }}
                />
            </div>
        </WFakeForm>
    );

    const error = !!use_3ds_verification && (
        <NoticeBlock color="error">
            <TODO>3DS verification while adding is not supported</TODO>
        </NoticeBlock>
    );

    return (
        <FormBox>
            {content}
            {error}
        </FormBox>
    );
}

function splitExpiration(expiration: string): [number, number] {
    const parts = expiration.split("/");
    const twoParts = [parts[0], parts.slice(1).join("")];
    return twoParts.map((v) => parseInt(v, 10)) as [number, number];
}

export default OmiseCardAddForm;
