import type {
    DuitkuRedirectSendDataResponse,
    LinkParamsGeneric,
    MethodConfig,
    ProcessorConfig,
} from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import invariant from "tiny-invariant";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import type { TopUpParams, TopUpProps } from "../topup/TopUpParams.ts";
import { creditListRequest, getRedirectUrlFromData, linkSendData, pollForSuccess } from "../topup/topUpUtils.ts";

export function DuitkuTopUpForm({
    actionsRef,
    method: { key },
}: { config: ProcessorConfig & { type: "link" }; method: MethodConfig } & TopUpProps) {
    //#region Hooks

    const apiClient = useApiClient();
    const signal = useUnmountSignal();

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return true;
        },

        async topUp(params: TopUpParams) {
            const { progress, popup, account } = params;

            progress("Starting payment");
            const sendResult = await linkSendData<LinkParamsGeneric>(apiClient, params, "duitku", {
                paymentMethod: key,
            });

            const data = sendResult.data as DuitkuRedirectSendDataResponse;

            invariant("redirect_url" in data, "Expected redirect_url in response");

            progress("Redirecting to payment gateway");
            invariant(popup, "Popup must be provided");
            popup.handleRedirect(getRedirectUrlFromData(data));

            // await popup.returnFromRedirect(signal);

            invariant(
                "link_response" in data && "reference" in data.link_response,
                "Expected reference in link_response",
            );
            const { reference } = data.link_response;

            progress("Waiting for payment confirmation");
            return await pollForSuccess({
                signal,
                popup,
                check: async () => {
                    const creditList = await creditListRequest(apiClient, account.id, signal);
                    return creditList?.[0].reference === `duitku::${reference}`;
                },
            });

            // TODO: Handle return: "?merchantOrderId=1724409970073146&resultCode=00&reference=D589424JR13SC0163ME110"
        },
    }));
    //#endregion

    return null;
}

export default DuitkuTopUpForm;
