import { useEffect } from "react";
import { useOnce } from "./useOnce.ts";

export class UnmountedError extends Error {
    name = "UnmountedError";
}

/** Aborts with an {@link UnmountedError} when the component is unmounted */
export function useUnmountSignal() {
    const controller = useOnce(() => new AbortController());
    useEffect(() => {
        // Abort on unmount
        return () => {
            console.debug("Unmount, sending abort signal");
            controller.abort(new UnmountedError());
        };
    }, [controller]);

    return controller.signal;
}
