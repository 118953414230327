import { ModalResult, WModalContent } from "../../../components/modal/WModal.tsx";
import type { EBillingAccount } from "../billingLogic.tsx";
import { ChooseMethod, type ChooseMethodProps } from "./ChooseMethod.tsx";
import { useAddMethod, type UseAddMethodProps } from "./useAddMethod.tsx";

export interface AddPaymentMethodModalProps extends UseAddMethodProps {
    billingAccount: EBillingAccount;
    onlyAutomated?: ChooseMethodProps["defaultOnlyAutomated"];
}

export function AddPaymentMethodModal({
    billingAccount: { account },
    onlyAutomated,
    ...useProps
}: AddPaymentMethodModalProps) {
    const adder = useAddMethod(useProps);

    return (
        <WModalContent
            title="Add Payment Method"
            label="Add"
            footerNotice={!adder.hasSelectedMethod ? "Please select an item in the list" : undefined}
            isActionDisabled={!adder.hasSelectedMethod}
            modalAction={async () => {
                if (!(await adder.validate())) {
                    return ModalResult.KEEP_OPEN;
                }
                await adder.addPaymentMethod({ account });
                return ModalResult.CLOSE;
            }}
        >
            <ChooseMethod hasAutomatedSwitch defaultOnlyAutomated={onlyAutomated} scrollToSelected {...adder.props} />
        </WModalContent>
    );
}

export default AddPaymentMethodModal;
