import type { LinkMethod } from "../PaymentMethod.tsx";
import { linkDefaults } from "../paymentMethodDefaults.tsx";
import ClictopayTopUpForm, { type ClictopayLinkProcessorConfig } from "./ClictopayTopUpForm.tsx";

export function makeClictopayLink(config: ClictopayLinkProcessorConfig) {
    return config.methods.map((method): LinkMethod => {
        const base = linkDefaults(config, method);
        return {
            ...base,
            TopUpForm: (props) => <ClictopayTopUpForm {...props} method={method} config={config} />,
        };
    });
}
