import type { ProcessorConfig } from "@warrenio/api-spec/spec.oats.gen";
import type { LinkMethod } from "../PaymentMethod.tsx";
import { linkDefaults } from "../paymentMethodDefaults.tsx";
import DuitkuTopUpForm from "./DuitkuTopUpForm.tsx";

export function makeDuitkuLink(config: ProcessorConfig & { type: "link" }) {
    return config.methods.map((method): LinkMethod => {
        const base = linkDefaults(config, method);
        return {
            ...base,
            TopUpForm: (props) => <DuitkuTopUpForm {...props} method={method} config={config} />,
        };
    });
}
