import type { StripeError } from "@stripe/stripe-js";

class StripeException extends Error {
    name = "StripeError";
    constructor(public readonly error: StripeError) {
        super(error.message);
    }
}
/** Utility function to convert a Stripe error into an thrown exception. Simplifies error handling & rollback. */
export function throwStripeError(error: StripeError | undefined): asserts error is undefined {
    if (error) {
        throw new StripeException(error);
    }
}
