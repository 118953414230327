import type { BillingAccountCard } from "@warrenio/api-spec/spec.oats.gen";
import type { Ref } from "react";
import type { EBillingAccount } from "../billingLogic.tsx";
import type { PaymentPopup } from "./popup.ts";

export const enum TopUpResult {
    VALIDATION_FAILED = "VALIDATION_FAILED",
    /** eg. modal was closed */
    CANCELLED = "CANCELLED",
    SUCCESS = "SUCCESS",
}

export interface TopUpParams {
    account: EBillingAccount;
    currency: string;
    amount: number;

    /** Update progress information */
    progress: (status: string) => void;

    /** NB: Only available for creditcard-type methods */
    getCard: () => BillingAccountCard;

    popup: PaymentPopup | undefined;
    returnUrl: string;
}

/** Actions exposed by a "Top up" form */
export interface TopUpActions {
    /** @returns should a pop-up window be opened to display the progress and handle any redirects? */
    needsPopUp?: (params: TopUpParams) => boolean;

    topUp(params: TopUpParams): Promise<TopUpResult>;
}

/** Properties for a "Top up" form */
export interface TopUpProps {
    isSubmitting: boolean;
    actionsRef: Ref<TopUpActions>;
    account: EBillingAccount;
}
