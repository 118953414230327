import { useAtomValue } from "jotai/react";
import { useImperativeHandle } from "react";
import { useApiClient } from "../../api/apiClient.store.ts";
import { raiseToast } from "../../notifications/toast.tsx";
import { SupportEmail } from "../../support/SupportLink.tsx";
import { creditCardProcessorAtom } from "../paymentProcessorsLogic.tsx";
import type { AddMethodProps } from "./AddMethodParams.ts";
import { FormBox } from "./FormBox.tsx";
import { applyForInvoiceRequest } from "./addMethodUtils.ts";

export function InvoiceForm({ actionsRef }: AddMethodProps) {
    //#region Hooks
    const hasCreditCardProcessor = !!useAtomValue(creditCardProcessorAtom);
    const apiClient = useApiClient();

    useImperativeHandle(actionsRef, () => ({
        // eslint-disable-next-line @typescript-eslint/require-await
        async validate() {
            return true;
        },
        async addPaymentMethod({ account }) {
            const applyResult = await applyForInvoiceRequest(apiClient, { billing_account_id: account.id });
            if (applyResult.id) {
                const message = applyResult.is_verified ? "Invoice payment activated" : "Application submitted";
                raiseToast({ type: "success", icon: "jp-wallet-icon", message });
            } else {
                throw new Error("Applying for invoice payment failed");
            }
        },
    }));
    //#region Hooks

    return (
        <FormBox>
            <p>
                If the invoice payment method is not activated instantly, we will contact you with more details. If you
                do not receive an email from us soon or the invoice payment does not become active, you can contact us
                at <SupportEmail />.
            </p>
            {hasCreditCardProcessor && (
                <p className="mt-2">
                    We recommend to use a credit card while your request is being processed to activate services right
                    away.
                </p>
            )}
        </FormBox>
    );
}

export default InvoiceForm;
